<template>
    <div>
        <b-row>
            <b-col cols="10">
                    <h4 v-if="checkFlag">Please configure Essay question.</h4>
                    <h4 v-else>Please select excel sheet for bulk question saving</h4>
                </b-col>
                <b-col cols="2">
                    <toggle-button :width="75" :height="28" v-model="checkFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'enter', unchecked: 'load'}"/>
                </b-col>
            <b-form v-if="checkFlag" class="col-12"  @submit="onSubmit" @reset="onReset">
            <b-row>
                <b-col cols="6">
                    <label for="title">Enter Title</label>
                    <b-form-input v-model="writeEssay.title" placeholder="Enter Question Title"></b-form-input>
                </b-col>
                <b-col cols="6">
                    <label for="mock_ind">Select Question Type</label>
                    <b-form-select v-model="writeEssay.mock_ind" :options="options"></b-form-select>
                </b-col>
            </b-row>
            <b-col cols="12">
                <div class="form-group">
                    <label for="textarea">Enter Question</label>
                    <textarea spellcheck="false" v-model="writeEssay.question_desc" class="form-control" id="textarea" rows="10"></textarea>
                </div>
            </b-col>
                <div class="col-6 displayInline" align="right">
                    <b-button type="submit" variant="primary" :disabled="allFilled != true">{{buttonText}}</b-button>
                </div>
                <div class="col-6 displayInline" align="left">
                    <b-button type="reset" variant="outline-danger">Reset</b-button>
                </div>
            </b-form>
        </b-row>
        <b-row v-if="checkFlag" class="mt-4 ml-2 mr-2">
            <b-col cols="12">
                <b-alert
                :show="dismissCountDown"
                dismissible
                variant="outline-success"
                @dismissed="dismissCountDown=0"
                
                >
                Record saved successfully, dismiss in {{ dismissCountDown }} seconds...
                </b-alert>
            </b-col>
        </b-row>
        <b-row v-if="checkFlag" class="mt-4 ml-2 mr-2">
            <b-col cols="12">
                <b-table
                    id="multipleChoiceSingle"
                    :items="allQuestions"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :fields="fields"
                    sort-icon-left
                    small
                    outlined
                    responsive
                    >
                    <template #cell(question_id)="data">
                    <!-- `data.value` is the value after formatted by the Formatter -->
                    <b-button variant="outline-warning" @click="deleteRecord(data.value)">
                        <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                    <!-- <a href="#">{{data.value}}</a> -->
                </template>
                <template #cell(question_desc)="data">
                    <!-- `data.value` is the value after formatted by the Formatter -->
                    <read-more class="readmoreDiv" more-str="read more" :text="data.value" link="#" less-str="read less" :max-chars="100"></read-more>
                    <!-- <p class="transcript">{{data.value}}</p> -->
                </template>
                </b-table>
            </b-col>
            <b-col cols="12">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="multipleChoiceSingle"
                    ></b-pagination>
            </b-col>
        </b-row>
        <div v-if="!checkFlag" class="row mt-4 ml-2 mr-2">
            <div class="col-12">
                
                <div class="form-group">
                    <label for="excelFile">Select Excel file</label>
                    <b-form-file
                        id="excelFile"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        accept=".xlsx"
                        @change="onFileChange"></b-form-file>
                </div>
            </div>
            <div class="col-12 text-center">
                <b-button :disabled="!fileLoad" @click="readFile()" class="mr-4">Read File</b-button>
                <b-button :disabled="!readFileFlag" variant="primary" @click="saveRecordExcel(1)">{{saveRecord}}</b-button>
            </div>
        </div>
        <div v-if="!checkFlag" class="row mt-4 ml-2 mr-2">
            <div class="col-12">
            <ul id="console-textarea" >
                <li v-for="(item,index) in consoleLog" :key="index">
                    {{item}}
                </li>
            </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import readXlsxFile from 'read-excel-file'
export default {
    data(){
        return{
            buttonText:'Save',
            allFilled: false,
            dismissSecs: 5,
            dismissCountDown: 0,
            excelFile:null,
            fileLoad:false,
            checkFlag:true,
            readFileFlag:false,
            rowsLoadedArray:null,
            saveRecord:'Save Record',
            consoleLog:['Log Info.'],
            writeEssay:{
                title:'',
                question_desc:'',
                user_id:'1',
                time_slot:'20:00',
                url:'',
                mock_ind:'0'
            },
            allQuestions:null,
            perPage: 8,
            currentPage: 1,
            fields: [
            {
                key: 'question_id',
                label:'Delete',
                sortable: true
            },
            {
                key: 'title',
                label:'Title',
                sortable: false
            }
            ,
            {
                key: 'question_desc',
                label:'Description',
                sortable: false
            }
            ],
            options: [
            { value: '0', text: 'Please select an option' },
            { value: 'F', text: 'General' },
            { value: 'T', text: 'Mock Test' }
            ]
        }
    },
    methods:{
        ...mapActions({
            saveAnswer: 'writtingmodule/saveAnswer',
            saveQuestion:'writtingmodule/saveQuestion',
            questionservice: 'writtingmodule/fetchQuestion',
            deleteQuestion:'writtingmodule/deleteQuestion'
        }),
        ...mapGetters({
            answerResponse: 'writtingmodule/AnswerResponse',
            getQuestionList: 'writtingmodule/questions'
        }),
        onFileChange(e) {
            const file = e.target.files[0];
            this.excelFile=file
            this.fileLoad =true
            this.readFileFlag=false
            this.consoleLog.push('Selected file loaded.')
            // this.loadedFile = e.target.files
            // this.fileUrl = URL.createObjectURL(file);
        },
        readFile(){
            if(this.excelFile!=null){
            readXlsxFile(this.excelFile).then((rows) => {
                            //console.log(rows)
                            this.consoleLog.push('Total records retrieve from excel sheet ' +rows.length +'.')
                            this.fileLoad =false
                            this.readFileFlag=true
                            this.rowsLoadedArray=rows
                        })
            }
        },
        saveRecordExcel(index){
            if(this.rowsLoadedArray==null)
            {
                return this.$alert('No record found')
            }
            else{
                let today = new Date().toLocaleDateString()
                var item=this.rowsLoadedArray[index]
                //console.log(item)
                this.writeEssay={
                    title:item[1],
                    question_desc:item[2],
                    user_id:'1',
                    time_slot:today,
                    mock_ind:'F',
                    new_ind:item[4],
                    predicted_ind:(item[5])?'Y':'N',
                    exam_ind:(item[6])?'Y':'N',
                    url:''
                }
                var findRecord=this.allQuestions.filter((item)=>{
                    return item.title.trim().toLowerCase()==this.writeEssay.title.trim().toLowerCase()
                })[0]
                if(findRecord!=null){
                    this.writeEssay.url='configessay/UpdateBulkEssay'
                    this.writeEssay.question_id=findRecord.question_id
                }
                else {
                    this.writeEssay.url='configessay/InsertBulkEssay'
                }
                this.saveQuestion(this.writeEssay).then(()=>{
                        var res = this.answerResponse()
                        var id=0
                        var dte=new Date().toISOString().slice(0, 19).replace('T', ' ');
                        if(findRecord!=null){
                            id=findRecord.question_id
                        }
                        else{
                            id=res.data.insertId
                        }
                        this.consoleLog.push(res.message)
                        const param={
                            tableName:'tblEssayQuestion',
                            idName:'question_id', 
                            id:id,
                            predicted_ind:this.writeEssay.predicted_ind,
                            predicted_date:dte,
                            exam_ind:this.writeEssay.exam_ind,
                            title:this.writeEssay.title,
                            exam_date:dte,
                            type:'single',
                            url:'updatequestion/status',
                        }
                        //console.log(param)
                        this.saveQuestion(param).then(()=>{
                            var resStatus = this.answerResponse()
                            this.consoleLog.push(resStatus.message)
                            if(index<this.rowsLoadedArray.length-1){
                            this.saveRecordExcel(index+1)
                            }
                        })
                    });
                
            }
        },
        onReset(evt) {
            evt.preventDefault()
            this.resetFrom()
        },
        resetFrom(){
            this.writeEssay={
                title:'',
                question_desc:'',
                user_id:'1',
                time_slot:'20:00',
                url:'',
                mock_ind:'0'
            }
        },
        onSubmit(evt) {
            evt.preventDefault()
            //////console.log(JSON.stringify(this.writeEssay))
            this.writeEssay.url = 'configessay/createessay';
            this.writeEssay.user_id = '1'
            var vm = this
            this.saveQuestion(this.writeEssay).then(()=>{
                ////console.log(vm.answerResponse())
                vm.userAnswerResponse = vm.answerResponse().data
                vm.writeEssay.question_id = vm.userAnswerResponse.insertId
                vm.allQuestions.push( vm.writeEssay)
                vm.$parent.getQuestionTypes();
                //////console.log(vm.userAnswerResponse.insertId)

                vm.resetFrom();
                //////console.log(vm.answerShortQuestion)
                vm.dismissCountDown = vm.dismissSecs
                //////console.log(this.userAnswerResponse.message)
            });
        },
        deleteRecord(id){
            var data={
                url:'configessay/deleteessay/id='+id
            }
            var vm = this
             this.deleteQuestion(data).then(()=>{
                vm.allQuestions = this.allQuestions.filter(function (item) {
                return item.question_id != id;
                })
                ////console.log(vm.allQuestions)
                vm.$parent.getQuestionTypes();
            });
            ////console.log(id)
        }
    },
    watch:{
        writeEssay: {
            deep: true,
            handler () {
                this.allFilled = false
                if(this.writeEssay.title.trim() !== '' & this.writeEssay.question_desc.trim() !== ''
                    & this.writeEssay.time_slot.trim() !== '' )
                {
                    this.allFilled = true
                }
            }
        }
    },
    computed: {
      rows() {
          if(this.allQuestions == null){
              return 0
          }
          else{
              return this.allQuestions.length
          }
      }
    },
    beforeMount(){
        this.allQuestions = null
        this.questionservice('configessay/getAllEssays').then(()=>{
            this.allQuestions = this.getQuestionList().data
            ////console.log(this.allQuestions)
        })
    },
}
</script>

<style scoped>
textarea {
    height: 40vh;
    width: 100%;
    border: 1px solid #ccc;
    resize: none;
}
label
{
    margin-top: 10px;
text-transform: capitalize;
font-weight: 500;
}
.vue-js-switch{
    margin-top: 0px!important;
}
</style>