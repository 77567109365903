<template>
    <div class="row">
        <div class="questionList col-3">
            <b-list-group >
                <b-list-group-item  v-for="(question,index) in questionLists" :key="question.question_type_cde" class="d-flex justify-content-between align-items-center" @click="getQuestionDetail(question['question_type_cde'])" >
                    {{question["question_type_desc"]}}
                    <!-- Used this for user attempts -->
                    <b-badge variant="primary" pill>{{question['quest'+index]}}</b-badge>
                </b-list-group-item>
            </b-list-group>
        </div>
        <div class="questiondesc col-9" v-if="selectedquestion">
             <app-summarizetext v-if="selectedquestion=='6'"/>
             <app-writeEssay v-if="selectedquestion=='7'"/>
        </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import summarizetext from './summarizewrittentext'
import writeEssay from './writeEssay'
export default {
    components:{
        'app-summarizetext':summarizetext,
        'app-writeEssay':writeEssay
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            selectedquestion: null
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'createquestion/fetchQuestion'
        }),
        ...mapGetters({
            getQuestionList: 'createquestion/questions'
        }),
        getQuestionDetail(questionId){
            
            this.selectedquestion = questionId;
            ////console.log(this.selectedquestion)
        },
        getQuestionTypes(){
            this.questionservice('getquestiontypes/getwritingmodules').then(()=>{
                        this.questionLists = this.getQuestionList().data
                        ////console.log(this.questionLists)
                    })
        }
    },
    beforeMount(){
        this.getQuestionTypes()
    }
}
</script>
<style>
button.btn-outline-warning, button.btn-outline-success
{
    font-size: .8rem;
    font-size: 1rem;
    padding: 0.375rem 0.5rem;
}
</style>